<template>
  <crud-form
    :schema="schema"
    :model="form"
    :path="'mail-group'"
    :form-title="$t('addMailGroup')"
    :redirect-route="'MailGroupsList'"
  />
</template>

<script>
  import CrudForm from '../../components/CrudForm';
  import { schema } from './schema/group'
  import { form } from './forms/group'

  export default {
    name: 'AddMailGroup',
    components: {
      CrudForm
    },
    data: () => {
      return {
        schema,
        form,
      }
    },
  }
</script>
